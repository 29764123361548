import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-product-inventory',
  templateUrl: './product-inventory.component.html',
  styleUrls: ['./product-inventory.component.scss']
})
export class ProductInventoryComponent implements OnInit {
  editform: FormGroup
  _globals=GlobalService
  constructor(
    public _productServices: ProductsService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if(!this._productServices.pts.sku) this._productServices.pts.sku=this._globals.generateSKU()
    if(!this._productServices.pts.barcode) {
      this._productServices.pts.barcode=this._globals.generateSKU()
      this._productServices.pts.barcode = ((<any>this._productServices.pts.barcode * 1) + 23) + ''
    }
    this.initForm()
    // Auto PO---
    this.changeValidation()
    // Auto PO
  }


  initForm() {
    this.editform = this._fb.group({
      sku: [{value:this._productServices.pts.sku,disabled: this._productServices.isViewOnly}, Validators.required],
      barcode: [{value:this._productServices.pts.barcode,disabled: this._productServices.isViewOnly}, Validators.required],
      minLevel: [{value:this._productServices.pts.minLevel,disabled: this._productServices.isViewOnly},[Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),Validators.min(0)]],
      maxLevel: [{value:this._productServices.pts.maxLevel,disabled: this._productServices.isViewOnly},[Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),Validators.max(9999)]],
      reorderLevel: [{value:this._productServices.pts.reorderLevel,disabled: this._productServices.isViewOnly},[Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),Validators.min(0),Validators.max(9999)]],
      //  Auto PO------
      minOrderQty: [{value:this._productServices.pts.minOrderQty,disabled: this._productServices.isViewOnly},[Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),Validators.min(0)]],
      maxOrderQty: [{value:this._productServices.pts.maxOrderQty,disabled: this._productServices.isViewOnly},[Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),Validators.max(99999)]],
  //  Auto PO
    })
  }
  // Auto PO
  changeValidation() {
     
    this.editform.get('minLevel').valueChanges.subscribe((el)=>{
      this.setLevelValidations()
     })
    this.editform.get('maxLevel').valueChanges.subscribe((el)=>{
      this.setLevelValidations()
     })

     this.editform.get('minOrderQty').valueChanges.subscribe((el)=>{
      this.setOrderValidations()
     })
    this.editform.get('maxOrderQty').valueChanges.subscribe((el)=>{
      this.setOrderValidations()
     })
   }
   setLevelValidations() {
      let min = 0
      let max = 0
       min =  Number(this.editform.get('minLevel').value)
       max =  Number(this.editform.get('maxLevel').value)
     if(max > 0){
     this.editform.get('minLevel').setValidators([Validators.min(0),Validators.max(max)])
     }
      this.editform.get('maxLevel').setValidators([Validators.min(min),Validators.max(99999)])
      this.editform.updateValueAndValidity()
  }
  setOrderValidations() {
    let min = 0
    let max = 0
     min =  Number(this.editform.get('minOrderQty').value)
     max =  Number(this.editform.get('maxOrderQty').value)
   if(max > 0){
   this.editform.get('minOrderQty').setValidators([Validators.min(0),Validators.max(max)])
   }
      this.editform.get('maxOrderQty').setValidators([Validators.min(min),Validators.max(99999)])
      this.editform.updateValueAndValidity()
  }
  // Auto PO
  isFormValid() {
    return this.editform.valid
  }
}
