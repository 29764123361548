import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentProcessing, ShippingConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss']
})
export class PaymentProcessingComponent implements OnInit {

  _global = GlobalService
  
  transactionModeList : any[] =[
    {id:"test",value:"Test mode"},
    {id:"live",value:"Live mode"},
  ]

  paymentProcessingForm: FormGroup;
  paymentProcessingData: PaymentProcessing;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService,
              private _route: ActivatedRoute,
              private _router: Router,
            ) { }

  ngOnInit(): void {
    this.getPaymentProcessing();
    this.initForm()
    this.customeValidation(this.f.transactionMode.value)
    this.f.transactionMode.valueChanges.subscribe(value=> this.customeValidation(value))
  }
  customeValidation(value:any) {
      if(value.id=='test'){
        this.f.testPublishableKey.setValidators([Validators.required]);
        this.f.testSecretKey.setValidators([Validators.required]);
        this.f.livePublishableKey.clearValidators();
        this.f.liveSecretKey.clearValidators();
      }else{
        this.f.livePublishableKey.setValidators([Validators.required]);
        this.f.liveSecretKey.setValidators([Validators.required]);
        this.f.testPublishableKey.clearValidators();
        this.f.testSecretKey.clearValidators();
      }
      this.f.testPublishableKey.updateValueAndValidity();
      this.f.testSecretKey.updateValueAndValidity();
      this.f.livePublishableKey.updateValueAndValidity();
      this.f.liveSecretKey.updateValueAndValidity();
  }

  
  initForm() {
    this.paymentProcessingForm = this._fb.group({
      transactionMode: [""],
      testPublishableKey: [""],
      testSecretKey: [""],
      livePublishableKey: [""],
      liveSecretKey: [""],
     })
  }
  get f() {
    return this.paymentProcessingForm.controls;
  }
  set f(values: any) {
    if (this.paymentProcessingForm) {
      this.paymentProcessingForm.setValue(values);
    }
  }
  getPaymentProcessing(){
    try{
      this._abd.showSpinner()
      this._businessSetupService.getPaymentProcessing().subscribe(res=>{
        if(res.success){
          this.paymentProcessingData = res.data
         this.setValues(this.paymentProcessingData)
          this._abd.hideSpinner()
        }
      })
    }
    catch{
      this._abd.hideSpinner()
    }
  }
  setValues(values:any) {
    this.f = {
      transactionMode:values.transactionMode,
      testPublishableKey:values.testPublishableKey,
      testSecretKey:values.testSecretKey,
      livePublishableKey:values.livePublishableKey,
      liveSecretKey:values.liveSecretKey,
    }
}
  saveFn(){
    let paymentProcessingValue: any = {
      transactionMode: this.f.transactionMode.value.id,
      testPublishableKey: this.f.testPublishableKey.value, 
      livePublishableKey: this.f.livePublishableKey.value, 
      testSecretKey: this.f.testSecretKey.value, 
      liveSecretKey: this.f.liveSecretKey.value, 
      isSaved:true,
    }
    try{
      this._abd.showSpinner()
      this._businessSetupService.updatePaymentProcessing(this.paymentProcessingData.id,paymentProcessingValue).subscribe(res=>{
        if(res.success){
          // this._abd.systemConfigs.paymentProcessing.isSaved = true
            this.goToRoute()
          this._connMessageService.showToastMessage(`Payment Processing saved successfully!`, 'success')
          this.getPaymentProcessing()
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving Payment Processing!`, 'error')
      this._abd.hideSpinner()
    }
  }
  goToRoute() {
    let page = null;
    let route = null
  
    this._route.queryParams.subscribe(params => {
      page = params['page']; 
    });
    if(page !== undefined){
      if(page.includes('payment-processing')){
      //  this._router.navigate([""])
      }
    }
  }
  cancelButtonClick(){
    this._businessSetupService.showPrintingConfiguration = false
  }

  }
  