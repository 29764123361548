import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export type MenuMode =
    | 'static'
    | 'overlay'
    | 'horizontal'
    | 'slim'
    | 'slim-plus'
    | 'reveal'
    | 'drawer';

export type ColorScheme = 'light' | 'dark' | 'dim';

export type MenuColorScheme = 'colorScheme' | 'primaryColor' | 'transparent';

export interface AppConfig {
    inputStyle: string;
    colorScheme: ColorScheme;
    theme: string;
    ripple: boolean;
    menuMode: MenuMode;
    scale: number;
    menuTheme: MenuColorScheme;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
    sidebarActive: boolean;
    anchored: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private _config: AppConfig = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'indigo',
        scale: 14,
        menuTheme: 'colorScheme',
    };

    // Using BehaviorSubject to hold the config state and allow subscribers
    private configSubject: BehaviorSubject<AppConfig> = new BehaviorSubject(this._config);
    public config$ = this.configSubject.asObservable();

     state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        sidebarActive: false,
        anchored: false,
    };

    private configUpdate = new Subject<AppConfig>();
    private overlayOpen = new Subject<any>();

    configUpdate$ = this.configUpdate.asObservable();
    overlayOpen$ = this.overlayOpen.asObservable();

    constructor() {}

    // This method is used to update the config
    updateConfig(config: AppConfig) {
        if (this.updateStyle(config)) {
            this.changeTheme(config);
        }
        this.changeScale(config.scale);
        this.stateChange(config);
        this.configSubject.next(config); // Updating the BehaviorSubject with the new config
    }

    // Checking if the style has changed
    updateStyle(config: AppConfig): boolean {
        return (
            config.theme !== this._config.theme ||
            config.colorScheme !== this._config.colorScheme
        );
    }

    // Handling menu toggle
    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;

            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        } else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    // Open submenu for overlay
    onOverlaySubmenuOpen() {
        this.overlayOpen.next(null);
    }

    // Show profile sidebar
    showProfileSidebar() {
        this.state.profileSidebarVisible = true;
    }

    // Show config sidebar
    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    // Check if menu mode is overlay
    isOverlay(): boolean {
        return this.configSubject.value.menuMode === 'overlay';
    }

    // Check if screen is desktop
    isDesktop(): boolean {
        return window.innerWidth > 991;
    }

    // Check if menu mode is slim
    isSlim(): boolean {
        return this.configSubject.value.menuMode === 'slim';
    }

    // Check if menu mode is slim-plus
    isSlimPlus(): boolean {
        return this.configSubject.value.menuMode === 'slim-plus';
    }

    // Check if menu mode is horizontal
    isHorizontal(): boolean {
        return this.configSubject.value.menuMode === 'horizontal';
    }

    // Check if screen is mobile
    isMobile(): boolean {
        return !this.isDesktop();
    }

    // Updating the config state
    stateChange(config: AppConfig) {
        this._config = { ...config };
        this.configUpdate.next(config);
    }

    // Changing theme dynamically
    changeTheme(config: AppConfig) {
        const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
        const themeLinkHref = themeLink.getAttribute('href')!;
        const newHref = themeLinkHref
            .split('/')
            .map((el) =>
                el === this._config.theme
                    ? el = config.theme
                    : el === `theme-${this._config.colorScheme}`
                    ? el = `theme-${config.colorScheme}`
                    : el
            )
            .join('/');

        this.replaceThemeLink(newHref);
    }

    // Replacing the theme link dynamically in the DOM
    replaceThemeLink(href: string) {
        const id = 'theme-link';
        let themeLink = <HTMLLinkElement>document.getElementById(id);
        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);
        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    // Changing the font size based on scale
    changeScale(value: number) {
        document.documentElement.style.fontSize = `${value}px`;
    }
}
