<div class="flex subscription-page py-3 flex-column justify-content-center conn-card">
    <div class="flex justify-content-center align-items-start my-1">
        <div class="flex">
            <img [src]="_globals.whiteLabel.logo" alt="logo" width="150px" />
        </div>
    </div>
    <div class="flex justify-content-center flex-column p-4">
        <div class="grid" *ngIf="AllowQtyChange">
            <div class="col border-round-sm">
                <app-subscription-plans-header-desktop [currentSubscriptionId]="_signup.selectedPlan?.id"
                    (subscriptionDurationChanged)="subscriptionDurationChanged()"
                    *ngIf="_signup.headerOnDesktopScreen()"></app-subscription-plans-header-desktop>
            </div>
        </div>
        <div class="grid" *ngIf="AllowQtyChange">
            <div class="col">
                <app-subscription-plan-counter [currentSubscriptionId]="_signup.selectedPlan?.id"
                    (locationChanged)="locationChanged()" (registerChanged)="registerChanged()">
                </app-subscription-plan-counter>
            </div>
        </div>
        <div class="flex justify-content-center align-items-center my-2">
            <div class="flex justify-content-center w-10 align-items-center my-2">
                <!-- <div class=" default-cursor text-xl flex justify-content-center text-left font-bold text-lg">
                     Your selected plan:&nbsp; <span class="selected-plan  page-heading-color"> 
                        {{ _signup.selectedPlan.title
                        }}
                    </span>
                </div> -->
                <div class=" default-cursor text-xl flex justify-content-center text-canter font-bold text-lg"
                    *ngIf="_signup.currentSubscriptionPlan !== '' && _signup.currentSubscriptionPlan !== undefined">
                    Your current plan: &nbsp;<span class="selected-plan  page-heading-color">{{_signup.currentSubscriptionPlan
                        }}
                    </span>
                </div>

            </div>

        </div>
        <div class="w-full overflow-auto h-full" *ngIf="_signup.onDesktopScreen()">
            <div class="flex w-full " [ngClass]="{'justify-content-center': isLastCheckoutPage}">
                <div class="flex frozen-first-column flex-column p-0" [ngClass]="{
                        'xl:col-2':!showLessPlans,
                        'xl:col-3':showLessPlans
                    }">
                    <div class="text-center align-items-center price-header plane-button-primary" [ngClass]="{'single-location-height':(_signup.outerLocationQty == 1),
    'multi-location-height':(_signup.outerLocationQty > 1 || _signup.outerRegisterQty > 1)}">
                        <div class="text-left mt-5 ml-4">
                            <p class="text-2xl font-medium text-dark"> Total Cost</p>
                            <p style="color: #c3c3c3; font-size: 13px">
                                Based on Your Selection
                            </p>
                        </div>
                    </div>
                    <div class=" border-left-1 border-bottom-1" style="border: 0.5px solid #efefef"
                        *ngIf="_signup.subscriptionPlans[0]?.featureData != undefined">
                        <div class="card-list-style-hndler-title"
                            *ngFor="let item of _signup.subscriptionPlans[0]?.featureData">
                            <ul class="m-0 p-0">
                                <li>{{ item.title }}</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <ng-container *ngFor="let card of _signup.subscriptionPlans; let i = index">
                    <app-subscription-plan-card-data-desktop [card]="card" [cardindex]="i"
                        (planChanged)="planChanged($event)" [locationChangedTrigger]="_signup.outerLocationQty"
                        [registerChangedTrigger]="_signup.outerRegisterQty"
                        [selectedPlanTimeframe]="_signup.selectedPlanTimeframe" [ngClass]="{
                            'xxl:col-2': showLessPlans && i < 3,
                            'xxl:col-3': showLessPlans && i > 1,
                            'xl:col-2': showLessPlans && i < 3,
                            'xl:col-2': showLessPlans && i > 1,
                            'xl:col-2': !showLessPlans,
                            'xxl:col-2': !showLessPlans,
                            'lg:col-2': showLessPlans,
                            'lg:col-6': !showLessPlans,
                            'hidden': !card.showPlan
                        }" class="p-0">
                    </app-subscription-plan-card-data-desktop>
                </ng-container>

            </div>
        </div>

        <!-- <app-subscription-plan-details></app-subscription-plan-details> -->

        <!-- <div class="flex justify-content-end flex-wrap my-2 align-items-center" *ngIf="showFooter && !_signup.accountVerified">
        <p class="mb-0">Looking for a</p>
        <p class="font-bold mx-1 mb-0">"Free Trial"</p>?

         <button pButton pRipple label="Click Here" (click)="freeTrialSelected()"
             class="signup-back-button mx-3"></button>
        <p class="mx-1"><a href="javascript:void(0)" (click)="freeTrialSelected()">Click Here</a></p>
    </div> -->
        <!-- *ngIf="showFooter" -->
        <div class="flex justify-content-between flex-wrap card-container purple-container mt-6">
            <div class="flex align-items-center justify-content-center">

            </div>
            <div class="flex align-items-center justify-content-center">
                <p-button label="Compare Plans" *ngIf="showFooter" (onClick)="comparePlanRedirect()"
                    class="signup-back-button mx-3">
                </p-button>
                <p-button *ngIf="showFooter" label="Continue" icon="pi pi-arrow-right" iconPos="right"
                    (onClick)="continueButtonClick()"></p-button>
                <!--[disabled]="_signup.freeTrialAccount" -->
            </div>
        </div>
    </div>

</div>