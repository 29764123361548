import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SecurityConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-security-configuration',
  templateUrl: './security-configuration.component.html',
  styleUrls: ['./security-configuration.component.scss']
})
export class SecurityConfigurationComponent implements OnInit {
  securityConfigurationform: FormGroup;
  securitySettingionData: SecurityConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.securitySettingionData = {
      active: this._abd.systemConfigs.securityConfig.active,
      enableLogInByUsernamePassword: this._abd.systemConfigs.securityConfig.enableLogInByUsernamePassword,
      enableLogInByCard: this._abd.systemConfigs.securityConfig.enableLogInByCard,
      enableLogInByPin: this._abd.systemConfigs.securityConfig.enableLogInByPin,
      enableLogInByFingerPrint: this._abd.systemConfigs.securityConfig.enableLogInByFingerPrint,
      idleScreenLockTime: this._abd.systemConfigs.securityConfig.idleScreenLockTime || 0,
    }
    setTimeout(() => {
          this.securityConfigurationform.get('active')?.valueChanges.subscribe((v)=>{
      this.disableControls(v)
    })
    }, 500);

    //  this.securityConfigurationform.get('active')?.valueChanges
    //  .subscribe(value=>)
  }

  ngAfterContentInit() {
    this.initForm()
  }

  disableControls(value){
    if(value==false){
      this.securityConfigurationform.get('enableLoginUserName')?.disable();
      this.securityConfigurationform.get('enableLoginCard')?.disable();
      this.securityConfigurationform.get('enableLogInByPin')?.disable();
      this.securityConfigurationform.get('enableLoginFingerPrint')?.disable();
      this.securityConfigurationform.get('idleScreenLockTime')?.disable();
    }else if (value==true){
      this.securityConfigurationform.get('enableLoginUserName')?.enable();
      this.securityConfigurationform.get('enableLoginCard')?.enable();
      this.securityConfigurationform.get('enableLogInByPin')?.enable();
      this.securityConfigurationform.get('enableLoginFingerPrint')?.enable();
      this.securityConfigurationform.get('idleScreenLockTime')?.enable();
    }
  }

  initForm() {
    this.securityConfigurationform = this._fb.group({
      active: [this.securitySettingionData.active],
      enableLoginUserName : [{value:this.securitySettingionData.enableLogInByUsernamePassword,disabled:true}],
      enableLoginCard : [this.securitySettingionData.enableLogInByCard],
      enableLogInByPin : [this.securitySettingionData.enableLogInByPin],
      enableLoginFingerPrint : [this.securitySettingionData.enableLogInByFingerPrint],
      idleScreenLockTime : [this.securitySettingionData.idleScreenLockTime,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)],
    })
  }

  saveFn(){
    let securityValue: SecurityConfig = {
      active: this.securityConfigurationform.get('active').value,
      enableLogInByUsernamePassword: this.securityConfigurationform.get('enableLoginUserName').value,
      enableLogInByCard: this.securityConfigurationform.get('enableLoginCard').value,
      enableLogInByPin: this.securityConfigurationform.get('enableLogInByPin').value,
      enableLogInByFingerPrint: this.securityConfigurationform.get('enableLoginFingerPrint').value,
      idleScreenLockTime: Number(this.securityConfigurationform.get('idleScreenLockTime').value),
       isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveSecurityConfiguration(securityValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.securityConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Security settings saved successfully!`, 'success')
          this._businessSetupService.showSecurityConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._abd.systemConfigs.securityConfig = res.data.securityConfig
              this._businessSetupService.initWidget("security");

              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving security settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showSecurityConfiguration = false
  }

}
