<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <!-- <label class="control-label"
                    ><strong
                        >Invoice Configuration
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                > -->
                <br />
                <form
                    [formGroup]="invoiceform"
                    (ngSubmit)="invoiceform.valid && saveFn()"
                    autocomplete="off"
                >

                    <div class="mb-4">
                        <p-inputSwitch [formControl]="$any(invoiceform.controls)['enableInvoiceForm']" inputId="rememberme20"></p-inputSwitch>
                        <label class="control-label" for="rememberme20"><strong> Enable</strong></label>
                    </div>
                    <br/>
                    <!-- <label class="control-label"
                        ><strong>Cash Void Limit ($) </strong></label>
                    <app-text-input
                        [formControl]="$any(invoiceform.controls)['cashVoidLimit']"
                        [label]=""
                        [name]="'Cash Void Limit ($) Value'"
                        (input)="10"
                        [description]=""
                    ></app-text-input>
                    <div
                        *ngIf="f.cashVoidLimit.touched && f.cashVoidLimit.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.cashVoidLimit.errors.pattern">Value should be number</div>
                    </div>
                    <br/> -->

                    <label class="control-label"
                        ><strong>Max Discount (%) </strong></label>
                    <app-text-input
                        [formControl]="$any(invoiceform.controls)['maxDiscountPercent']"
                        [label]=""
                        [name]="'Max Discount (%) Value'"
                        (input)="10"
                        [description]=""
                    ></app-text-input>
                    <div
                        *ngIf="f.maxDiscountPercent.touched && f.maxDiscountPercent.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.maxDiscountPercent.errors.pattern">Value should be number</div>
                    </div>
                    <br/>

                    <label class="control-label"
                        ><strong>Max Discount ($) </strong></label>
                    <app-text-input
                        [formControl]="$any(invoiceform.controls)['maxDiscountDollar']"
                        [label]=""
                        [name]="'Max Discount ($) Value'"
                        (input)="10"
                        [description]=""
                    ></app-text-input>
                    <div
                        *ngIf="f.maxDiscountDollar.touched && f.maxDiscountDollar.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.maxDiscountDollar.errors.pattern">Value should be number</div>
                    </div>
                    <br/>

                    <!-- <label class="control-label"
                        ><strong>Max Line Item Price ($)</strong></label>
                    <app-text-input
                        [formControl]="$any(invoiceform.controls)['maxLineItemPrice']"
                        [label]=""
                        [name]="'Max Line Item Price ($) Value'"
                        (input)="10"
                        [description]=""
                    ></app-text-input>
                    <div
                        *ngIf="f.maxLineItemPrice.touched && f.maxLineItemPrice.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.maxLineItemPrice.errors.pattern">Value should be number</div>
                    </div>
                    <br/> -->

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(invoiceform.controls)['itemDiscount']"
                            inputId="rememberme1"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme1">Allow Item Discount</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(invoiceform.controls)['allowInvoiceDiscount']"
                            inputId="rememberme2"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme2">Allow Invoice Discount</label>
                    </div>
                    <br/>

             <!--       <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(invoiceform.controls)['overrideDiscount']"
                            inputId="rememberme3"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme3">Override Item Discount If Invoice Discount Apply</label>
                    </div> -->
                    <br/>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!invoiceform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
