<div class="main p-5">
  <span class="text-3xl font-semibold">
    Your Webstore
  </span>
  <div class="py-2 tc">
    Make quick changes to your webstore site with the easy-to-use editor. Move to the template of your choice <br> at any time and keep the progress you made with LincSell
  </div>
  <div class="main p-5 flex justify-content-between  ">
    <div>
      <div class="text-2xl font-medium py-3">Wasi Test Store 1 {{checkIsURL()}}</div>
      <div class="blueColor flex font-medium" *ngIf="checkIsURL()">{{getUrl(_global.webStoreConfiguration?.webStoreHost)}}<img class="px-3 cursor-pointer" src="assets/images/redirect.svg" alt="" (click)="goToSite()" ></div>
      <div class="py-5" >Preview your site to see what it looks like to your customers. Edit your online storefront <br> at any time to keep its appearance and content up to date.</div>

        <span class="edit-site-button cursor-pointer" (click)="goToEditer()">
        Edit your site <img src="assets/images/black_redirect.svg" alt="" class="px-3">
      </span>
    
    </div>
    <div class="image-container cursor-pointer">
      <img src="assets/images/theme_img.svg" alt="Image" class="img">
      <div  class="preview-text">Preview</div>
    </div>
    
  </div>
</div>