import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-signup-loyalty-program',
  templateUrl: './signup-loyalty-program.component.html',
  styleUrls: ['./signup-loyalty-program.component.scss']
})
export class SignupLoyaltyProgramComponent implements OnInit {

    @Input() showFooter = true
    @Output() backButtonClick = new EventEmitter();
    constructor(public _signup: SignupService) { }

  ngOnInit(): void {
      try {
          this._signup.loyaltyProgramPrice = this._signup.selectedPlan.featureData.filter(f => f.typeString == "LoyaltyProgram")[0].price
          let loyaltyProgramAppBaseData: any = sessionStorage.getItem('loyaltyProgramAppBaseData');
          if (loyaltyProgramAppBaseData != undefined && this._signup.loyaltyProgramPrice > 0) {
              loyaltyProgramAppBaseData = JSON.parse(loyaltyProgramAppBaseData)
              this._signup.loyaltyProgramAppBaseData.filter(f => f.id == loyaltyProgramAppBaseData.id)[0].selected = true;
          }
      } catch (error) {

      }
  }

    backClicked() {
        this.backButtonClick.emit(true);
        this._signup.currentStep--
        this._signup.savedSteps--;
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
        sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
    }

    nextClicked() {
        this._signup.currentStep++;
        this._signup.savedSteps++;
        if (this._signup.savedSteps > 8) {
            this._signup.savedSteps = 8
        }
        if (this._signup.loyaltyProgramPrice > 0) {
            if (this._signup.loyaltyProgramAppBaseData.filter(f => f.selected).length > 0) {
                sessionStorage.setItem('loyaltyProgramAppBaseData', JSON.stringify(this._signup.loyaltyProgramAppBaseData.filter(f => f.selected)[0]))
            }
            else {
                sessionStorage.removeItem('loyaltyProgramAppBaseData')
            }
        }
        else {
            sessionStorage.removeItem('loyaltyProgramAppBaseData')
        }
        this._signup.checkoutPageCalcVars.loyaltyProgram = this._signup.loyaltyProgramPrice;
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
        sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
    }

    selectLoyaltyProgramOption(o: any) {
        this._signup.loyaltyProgramAppBaseData.filter(f => f.id != o.id).map(f => f.selected = false)
        o.selected = !o.selected;
    }

}
