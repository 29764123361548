import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

@Component({
    selector: 'app-menu1',
    templateUrl: './app.menu.component.html'
})
export class AppMenu1Component implements OnInit {

    model: any[] = [];
  
     constructor( public abd:AppBaseDataService ){}


    ngOnInit() {
        this.abd.whichRoute = 'any'
        this.abd.initMenuList(false);
    }
}
