<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo">
             <img src="assets/images/LincSell-White_Logo.svg" alt="Logo">
        </a>
        <!-- <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button> -->
    </div>
 

    <div #menuContainer class="layout-menu-container">
        <app-menu1></app-menu1>
    </div>
</div>