import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-welcome-tour',
  templateUrl: './welcome-tour.component.html',
  styleUrls: ['./welcome-tour.component.scss'],
})

export class WelcomeTourComponent implements OnInit {
 
  _global = GlobalService
  currentTour:number = 1;

  
  constructor(
    private _userService: UserService,
    private _router: Router,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
   
 }



 skipTour(){
   this._global.currentTour = this._global.totalTour + 1
   this._abd.showSpinner()
 this._userService.isTourComplete().subscribe(res => {
   if (res.success) {
    localStorage.setItem('isTour','true')   
   }
 })


 }
 nextTour(){
  if(this._global.currentTour < this._global.totalTour){
    this._global.currentTour = this._global.currentTour +1 
  }else{
    this.skipTour()
  }
 }

}


