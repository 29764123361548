<div>
    <h2 class="bs-page-header mb-3">Purchase Order View</h2>
</div>
<!-- http://localhost:4200/#/app/purchase-orders-receive-open -->
<ng-container *ngIf="_purchaseService.isSendEmail">
    <p-dialog
        header="Purchase Order Email"
        [(visible)]="_purchaseService.isSendEmail"
        [style]="{ width: '60%' }"
        modal="true"
    >
    <app-po-send-email [emailData]="completeData" (onOpenPO)="openInNewTab()"></app-po-send-email>
    </p-dialog>
</ng-container>
<p-progressBar mode="indeterminate" *ngIf="!isDataLoaded"></p-progressBar>

<div *ngIf="isDataLoaded" class="flex justify-content-end align-items-center  ">

<button
*ngIf="isSendEmailEnable"
style="color: var(--brandColor)"
pButton
pRipple
pTooltip="Send Email"
type="button"
icon="pi pi-send"
tooltipPosition="left"
class="p-button-rounded p-button-text p-button-info"
(click)="handlePdf('sendEmail')"
></button>
<button
style="color: var(--brandColor)"
pButton
pRipple
type="button"
icon="pi pi-download"
pTooltip="DownLoad Purchase Order"
tooltipPosition="left"
class="p-button-rounded p-button-text p-button-info"
(click)="handlePdf('savePdf')"
></button>        
<button
style="color: var(--brandColor)"
pButton
pRipple
type="button"
icon="pi pi-print"
tooltipPosition="left"
class="p-button-rounded p-button-text p-button-info"
pTooltip="Print Purchase Order"
(click)="handlePdf('printPdf')"
></button>        

</div>


<div class="grid" *ngIf="isDataLoaded">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">PO#</div>
                    <div class="col">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.poNumber"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <!-- <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">Receive #</div>
                    <div class="col">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.receiveNo"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div> -->

            <div class="col">
                <div class="grid">
                    <div class="col mt-3">Receive Date</div>
                    <div class="col">
                        <p-calendar
                            [(ngModel)]="activeItem.receiveDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            dateFormat="D d, MM yy"
                            [disabled]="true"
                        ></p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">Location</div>
                    <div class="col">
                        <p-dropdown
                            [options]="locations"
                            [(ngModel)]="
                                _purchaseService.newEditItem.locationObj
                            "
                            optionLabel="locationName"
                            [style]="{ width: '100%' }"
                            (onChange)="locationChanged($event)"
                            [disabled]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">
                        Vendor
                        <span
                            ><a
                                style="
                                    background-color: var(--bs-info);
                                    color: white;
                                    padding: 2px;
                                    border-radius: 50%;
                                "
                                class="text-red-600 font-bold"
                                href="javascript:void(0);"
                                (click)="showVendorInfoDialogFn()"
                                ><span
                                    ><i
                                        class="pi pi-info"
                                        style="color: white"
                                    ></i></span></a
                        ></span>
                    </div>
                    <div class="col">
                        <p-dropdown
                            [options]="vendors"
                            [(ngModel)]="activeItem.vendorObj"
                            optionLabel="vendorName"
                            [style]="{ width: '100%' }"
                            (onChange)="vendorChanged($event)"
                            [disabled]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">Created By</div>
                    <div class="col">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.requestedBy"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">Method</div>
                    <div class="col">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.poType"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">Status</div>
                    <div class="col">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="_purchaseService.newEditItem.POStatus === 'CLOSEDFORRECEIVING' ? 'Received' : _purchaseService.newEditItem.POStatus"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br />
<div class="card" *ngIf="isDataLoaded">
    <p-table
        [value]="dataList"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Sr#</th>
                <th>Barcode</th>
                <th>SKU</th>
                <th>Product</th>
                <th>UOM</th>
                <th style="text-align: center">Ordered Qty</th>
                <th style="text-align: center">Received Qty</th>
                <th style="text-align: center">Due Qty</th>
                <th style="text-align: right">Unit Cost($)</th>
                <th style="text-align: right">Discount (%)</th>
                <th style="text-align: right">Net Total($)</th>
                <th style="text-align: right">Tax (%)</th>
                <th style="text-align: right">Shipping($)</th>
                <th style="text-align: right">Total Cost($)</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.srNo }}</td>
                <td>
                    {{ item.barcode }}
                </td>
                <td>
                    {{ item.skuCode }}
                </td>
                <td>
                    {{ item.productName | slice : 0 : 25 }}
                    <span *ngIf="item.productName?.length > 25">...</span>
                </td>
                <td>{{ item.uomDescription }}</td>
                <td style="text-align: center">
                    {{ item.orderedQty }}
                </td>
                <td style="text-align: center">
                    {{ item.receivedQty }}
                </td>
                <td style="text-align: center">
                    {{item.dueQuantity}}
                </td>
                <td style="min-width: 90px">
                    <div class="flex justify-content-center">
                        <div class="">
                            <p-inputNumber
                                [(ngModel)]="item.costPrice"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                [inputStyle]="{
                                    width: '110px',
                                    'text-align': 'right'
                                }"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="costPriceChanged($event, item)"
                                [disabled]="true"
                            >
                            </p-inputNumber>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="flex justify-content-center">
                        <div class="">
                            <p-inputNumber
                                [(ngModel)]="item.discountPercent"
                                (onInput)="discountChanged($event, item)"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                [max]="100"
                                placeholder="0"
                                [inputStyle]="{
                                    width: '110px',
                                    'text-align': 'right'
                                }"
                                [disabled]="true"
                            >
                            </p-inputNumber>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="flex justify-content-center">
                        <div class="">
                            <p-inputNumber
                                [(ngModel)]="item.netTotal"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'right'
                                }"
                                [disabled]="true"
                            >
                            </p-inputNumber>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="flex justify-content-center">
                        <div class="">
                            <p-inputNumber
                                [(ngModel)]="item.taxRate"
                                (onInput)="taxChanged($event, item)"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                [inputStyle]="{
                                    width: '110px',
                                    'text-align': 'right'
                                }"
                                [disabled]="true"
                            >
                            </p-inputNumber>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="flex justify-content-end">
                        <div class="">
                            <p-inputNumber
                                [(ngModel)]="item.shipping"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'right'
                                }"
                                [disabled]="true"
                            >
                            </p-inputNumber>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="flex justify-content-end">
                        <div class="">
                            <p-inputNumber
                                [(ngModel)]="item.itemLevelItemCost"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'right'
                                }"
                                [disabled]="true"
                            >
                            </p-inputNumber>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="15" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <br />
    <div class="grid">
        <div class="col-4">
            <p class="mt-4 font-bold text-500 text-lg">Notes</p>
            <textarea
                style="width: 100%"
                pInputTextarea
                [(ngModel)]="comments1"
                [rows]="3"
                [disabled]="true"
            ></textarea>
           <!-- <div class="conn-card" *ngIf="isFooterNoteEnabled">
            <label [innerHTML]="footerNotesText"></label>
           </div> -->
        </div>

        <div class="col ml-4">
            <!-- <p-radioButton
                class="mt-7 flex text-lg text-500"
                name="groupname"
                value="valB"
                label="Enable Item Level Shipping Cost"
                [(ngModel)]="selectedOptions"
                (onChange)="onChangeValA($event)"
                [disabled]="true"
            ></p-radioButton>
            <p-radioButton
                class="mt-3 flex text-lg text-500"
                name="groupname"
                value="valA"
                label="Divide Shipping Cost  across all items"
                [(ngModel)]="selectedOptions"
                (onChange)="onChangeValA($event)"
                [disabled]="true"
            ></p-radioButton> -->
        </div>

        <div class="col">
            <div class="grid mt-5">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Sub Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="sumSubTotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Total Discount($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="sumDiscount"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Total Tax($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="sumTax"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Shipping($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="activeItem.shipping"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        (onInput)="shippingChanged($event, item)"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Grand Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="grandTotalNew"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [disabled]="true"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="flex justify-content-end flex-wrap mt-3">
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus !== 'RECEIVED'"
        >
            <button
                pButton
                type="button"
                label="Back"
                class="p-button-danger"
                style="width: 130px"
                (click)="cancelButtonClick()"
            ></button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus == 'OPEN'"
        >
            <button
                pButton
                type="button"
                label="Duplicate"
                class="p-button-info"
                style="width: 130px"
                (click)="createDuplicateOrder()"
                [disabled]="true"
            ></button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus == 'OPEN'"
        >
            <button
                pButton
                type="button"
                label="Proceed to Receive"
                class="p-button-help"
                style="width: 160px"
                (click)="receivePoClicked()"
            ></button>
        </div>
    </div>
</div>

<br />
<br />
<div class="flex justify-content-between my-2" >
    <h2 class="bs-page-header mb-3" *ngIf="isDataLoaded">
        Receive History for PO
    </h2>
    <button
    *ngIf="showInventoryReceiveBtn"
    pButton
    type="button"
    label="Post to Inventory"
    class="p-button-info"
    style="width: 160px"
    (click)="commitAllInventory()"
></button>
</div>
<div class="card" *ngIf="isDataLoaded">
    <p-table
        [value]="histList"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"

        dataKey="id"

    >
        <ng-template pTemplate="header">
            <tr>
                <th>Invoice#</th>
                <th>Receive#</th>
                <th>Date</th>
                <th style="text-align: center">SKU</th>
                <th>Product</th>
                <th>UOM</th>
                <th>Received</th>
                <th>Remaining</th>
                <th>Unit Cost($)</th>
                <th>Price A($)</th>
                <th>Price B($)</th>
                <th>Price C($)</th>
                <th>Discount%</th>
                <th>Net Cost($)</th>
                <th>Tax%</th>
                <th>Shipping($)</th>
                <th>Total Cost($)</th>
                <th style="text-align: center">Inventory Status</th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item">
                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.poRecivingId }}</td>
                <td>{{ item.dated | date : "shortDate" }}</td>
                <td style="text-align: center">{{ item.skuCode }}</td>
                <td>
                    {{ item.productName | slice : 0 : 25 }}
                    <span *ngIf="item.productName?.length > 25">...</span>
                </td>
                <td>{{ item.uomDescription }}</td>
                <td>{{ item.receivedQuantity | number : "1.0-0" }}</td>
                <td>{{ item.dueQuantity | number : "1.0-0" }}</td>
                <td>{{ item.costPrice | number : "1.2-2" }}</td>
                <td>{{ item.priceA | number : "1.2-2" }}</td>
                <td>{{ item.priceB | number : "1.2-2" }}</td>
                <td>{{ item.priceC | number : "1.2-2" }}</td>
                <td>{{ item.discountPercent }}</td>
                <td>{{ item.netTotal | number : "1.2-2" }}</td>
                <td>{{ item.taxRate }}</td>
                <td>{{ item.shipping | number : "1.2-2" }}</td>
                <td>{{ item.totalAmount | number : "1.2-2" }}</td>
                <td style="text-align: center">
                    {{ item.inventoryCommitStatus }}
                </td>
                <td>
                    <button
                        #btn
                        type="button"
                        pButton
                        icon="pi pi-bars"
                        label="Show"
                        (click)="histRowClicked($event, item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="18" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-tieredMenu
    #histmenu
    [model]="actionsHist"
    [popup]="true"
    style="width: 200px"
></p-tieredMenu>

<p-dialog

    [(visible)]="showReceiveInvoice"
    [style]="{ width: '92vw' }"
>
    <div class="grid">
        <div class="col-4">
            <div class="conn-card">
                <div class="grid">
                    <div class="col-5 text-lg">PO#</div>
                    <div class="col font-bold text-500">
                        {{ activeItem.poNumber }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5 text-lg">Receive #</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.id}}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Invoice Number</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.invoiceNumber }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Created Date</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.createdDate | date : "mediumDate" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="conn-card">
                <div class="grid">
                    <div class="col-5">Received By</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.receivedBy }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Received Date</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.receivedDate | date : "mediumDate" }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Status</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.poStatus }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Div. Ship. Item</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.dividedShippingItems ? "Yes" : "No" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="conn-card">
                <!-- <div class="grid">
                    <div class="col-5">Div. Ship. Item</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.dividedShippingItems ? "Yes" : "No" }}
                    </div>
                </div> -->
                <div class="grid">
                    <div class="col-5">Receiving Notes</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.notes  }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Shipping</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.shipping }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">TOTAL</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.grandTotal | currency }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br />
    <br />
    <div class="conn-card">
        <p-table
            [value]="receiveData?.rpoItems"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            rowHover="true"
            dataKey="id"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">Invoice#</th>
                    <th style="text-align: center">Product</th>
                    <th style="text-align: center">Item SKU</th>
                    <th style="text-align: center">Due Qty</th>
                    <th style="text-align: center">Received</th>
                    <th style="text-align: center">Remaining</th>
                    <th style="text-align: center">Unit Cost($)</th>
                    <th style="text-align: center">Price A($)</th>
                    <th style="text-align: center">Price B($)</th>
                    <th style="text-align: center">Price C($)</th>
                    <th style="text-align: center">Discount%</th>
                    <th style="text-align: center">Net Cost($)</th>
                    <th style="text-align: center">Tax%</th>
                    <th style="text-align: center">Shipping($)</th>
                    <th style="text-align: center">Total Cost($)</th>
                    <th style="text-align: center">Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">{{ item.invoiceNumber }}</td>
                    <td style="text-align: center">{{ item.productName }}</td>
                    <!-- <td style="text-align: center">
                        {{ item.createdDate | date : "shortDate" }}
                    </td> -->
                    <td style="text-align: center">{{ item.itemSKUId }}</td>
                    <td style="text-align: center">
                        {{ item.dueQuantity | number : "1.0-0" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.receivedQuantity | number : "1.0-0" }}
                    </td>
                    <td style="text-align: center">
                        {{
                            item.dueQuantity - item.receivedQuantity < 0
                                ? 0
                                : (item.dueQuantity - item.receivedQuantity
                                  | number : "1.0-0")
                        }}
                    </td>
                    <td style="text-align: center">
                        {{ item.costPrice | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.priceA | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.priceB | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.priceC | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.discountPercent }}
                    </td>
                    <td style="text-align: center">
                        {{ item.netTotal | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">{{ item.taxRate }}</td>
                    <td style="text-align: center">
                        {{ item.shipping | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.totalAmount | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.inventoryCommitStatus }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="18" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
    
