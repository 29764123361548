<div class="flex justify-content-center align-items-center  py-2" style="background-color: #000;">
    <img src="assets/images/lincsell_logo_white.png" width="32px" height="22px" alt="">
</div>
<div class="py-3">
  <div class="flex text-align-center f-24">
 <div class="w-full">
    <div class="title py-2">
   
            Welcome &nbsp; <b>{{_global?.userInfo?.displayName}}</b>
</div>

<p class="f-14">Thanks you for choosing Lincsell to bring your business online.<br>
    We're dedicated to helping you establish a strong online presence, and reach customer everywhere.
</p>
 </div>

</div>
    

<p-carousel [value]="dataList" [autoplayInterval]="5000" [circular]="true" [showNavigators]="false">

    <ng-template let-item pTemplate="item">
        <div class="flex justify-content-center my-3" style="height: 50vh;">
           <div class="flex justify-content-center align-items-center text-align-center flex-column">
            <span class="img py-3"  [style.background-image]="'url(' + item?.imageUrl + ')'">

            </span>
            <span class="second-title py-3"><b class="f-16">{{item.title}}</b>
               </span>
            <div class="f-14">
                {{item.description1}}
             </div>     
            <div class="f-14">
                {{item.description2}}
             </div>     
           </div>
       
        </div>
    </ng-template>
</p-carousel> 
<div class="flex justify-content-center align-items-center py-3 ">
    <span class="button cursor-pointer" (click)="goTOHome()" >
    Let's Begin    
    </span>
</div> 
</div>


   