import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Router } from '@angular/router';

import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Paginator } from 'primeng/paginator';
import { BarcodeLabelPrintingService } from 'src/app/_services/barcode-label-printing.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { ProductsService } from 'src/app/_services/products.service';
import { PurchaseService } from 'src/app/_services/purchase.service';

@Component({
  selector: 'app-auto-generate-purchase-order',
  templateUrl: './auto-generate-purchase-order.component.html',
  styleUrls: ['./auto-generate-purchase-order.component.scss']
})
export class AutoGeneratePurchaseOrderComponent implements OnInit {
  _globals = GlobalService

  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  doChangePage = true
  selectedRow: any = ''
  selectAll = false
  dataList: any = null

  //order
  orderParam = ''
  orderBy = 'asc'



  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    private _router: Router,
    public _purchaseService:PurchaseService,
    public _barcodeLabelServices:BarcodeLabelPrintingService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
  ) {}

  ngOnInit(): void {
    // this._globals.componentName = 'Auto PO'
    this.getDataList()
  }

  ngOnDestroy() {
    // this._globals.componentName = ''
  }
  callbackFunc(){

  }
  getDataList() {
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    let loc = ''
    if (!this._filterService.selectedLocation) loc = ''
    else loc = this._filterService.selectedLocation.locationId
    let ven = ''
    if (!this._filterService.selectedVendor) ven = ''
    else ven = this._filterService.selectedVendor.id

    const obj:any = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
      "LocationId": loc,
    }
    if(this._filterService.selectedVendor !== null){
     obj.vendorId = this._filterService.selectedVendor.id
    }
    this._abd.showSpinner()
    this._purchaseService.getAllPurchaseOrderList(obj).subscribe(res => {
      if (res.success) {
        this.dataList = res.data
        this.totalRecords = res.totalRecords
        this._abd.hideSpinner()

      }
    })
  }

  createPO(){
    if(this._purchaseService.poSelectedList.length > 0){
      for (let i = 0; i < this._purchaseService.poSelectedList.length; i++) {
        this._purchaseService.poSelectedList[i].receivedQty = 0
        this._purchaseService.poSelectedList[i].totalCost = 0
        this._purchaseService.poSelectedList[i].selected = false
      }
      this._purchaseService.poSelectedList = this._purchaseService.poSelectedList.map((el)=>{
        let uom = this._abd.uom.find(f => f.id ==el.uomId)
        return{
          ...el,
         uomId:el.uomId = uom.id,
         uomDescription:el.uomDescription = uom.unitName,
         productName:el.title,
         description:el.title,
         itemSKUId:el.id,
         itemSkuRealId:el.id,
         isValueChanged:el.isValueChanged = true,
         receivedQty:el.orderQuantity,
         subTotal:el.costPrice * el.receivedQty,
         discountPercent:el.discountPercent = 0,
         discountAmount:el.discountAmount = 0,
         taxAmount:el.taxAmount = 0,
         netTotal:el.subTotal - el.discountAmount,
         totalCost:el.netTotal - el.taxAmount,
         lineNotes:el.lineNotes = '',
         shipping:el.shipping = 0,
         notes:el.notes = '',
         selected:el.selected = false,
        }
      })
    this._purchaseService.isOpenAutoPO = false
    this._purchaseService.newEditItem.isNew = true
    this._router.navigate(['app/purchase-orders-create-edit'])
    }

  }
  
  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    this.getDataList()
  }

  selectAllChanged(event) {
    if (event.checked) {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      this._purchaseService.poSelectedList = this.dataList
      }
    } else {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }
  this._purchaseService.poSelectedList = this.dataList.filter(f => f.selected === true)
  }

  selectionChanged(event) {
    if (event.checked) {
     this._purchaseService.poSelectedList = this.dataList.filter(f => f.selected === true)
    }else{
      this._purchaseService.poSelectedList = this.dataList.filter(f => f.selected === true)
    }
  }


  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'stockInhand':
        this.orderParam = 'StockInhand'
        break;
      case 'minOrderQty':
        this.orderParam = 'MinOrderQty'
        break;
      case 'maxOrderQty':
        this.orderParam = 'MaxOrderQty'
        break;
      case 'orderQuantity':
        this.orderParam = 'OrderQuantity'
        break;
      case 'poStatus':
        this.orderParam = 'PoStatus'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }

  ClosePO(){
    this._purchaseService.isOpenAutoPO = false
    this._purchaseService.poSelectedList = []
  }
}
