

<div class="flex flex-column p-0" *ngIf="card.showPlan">
    <div class="text-center align-items-center price-header plane-button-primary" [ngClass]="{'single-location-height':(_signup.outerLocationQty == 1),
    'multi-location-height':(_signup.outerLocationQty > 1 || _signup.outerRegisterQty > 1)}">

        <div *ngIf="cardindex > -1">
            <h6 class="lite mb-3">{{ card.title }}</h6>
            <div *ngIf="
                    cardindex > -1 &&
                    cardindex < _signup.subscriptionPlans.length - 1
                ">
                <p class="text-2xl font-medium text-dark">
                    {{ card.grandTotal | currency }}
                </p>
            </div>

              <p class="mb-2" *ngIf="
                    card.isTrial" style="color: #c3c3c3; font-size: 13px">
                  Credit card not required
              </p>
            <p class="mb-2" *ngIf="
                    _signup.selectedPlanTimeframe == 'BilledMonthly' && !card?.isTrial &&
                    cardindex < _signup.subscriptionPlans.length - 1
                " style="color: #c3c3c3; font-size: 13px">
                /month billed monthly
            </p>
            <p class="mb-2" *ngIf="
                    _signup.selectedPlanTimeframe !== 'BilledMonthly' && !card?.isTrial &&
                    cardindex < _signup.subscriptionPlans.length - 1
                " style="color: #c3c3c3; font-size: 13px">
                /month billed annually
            </p>
            <p class="mb-2 text-size font-medium text-dark" *ngIf="
                    cardindex > -1 &&
                    cardindex == _signup.subscriptionPlans.length - 1
                ">
                {{ card.durationType }}
            </p>
            <div *ngIf="card.orderNo == 5" class="extra-space"></div>
            <div class="mb-4 additional-value" *ngIf="_signup.outerLocationQty > 1 || _signup.outerRegisterQty > 1">
                <div class="flex justify-content-between">
                    <p *ngIf="
                            cardindex > -1 &&
                            cardindex <= _signup.subscriptionPlans.length - 1
                        " class="text-sm">
                        Base Plan Price
                    </p>
                    <p *ngIf="
                            cardindex > -1 &&
                            cardindex < _signup.subscriptionPlans.length - 1
                        " class="font-bold">
                        {{ card.subscriptionBasePlanPrice | currency }}
                    </p>
                    <p *ngIf="
                            cardindex > -1 &&
                            cardindex == _signup.subscriptionPlans.length - 1
                        " class="font-bold">
                        -
                    </p>
                </div>
                <div class="flex justify-content-between">
                    <p class="text-sm" *ngIf="
                            cardindex > -1 &&
                            cardindex <= _signup.subscriptionPlans.length - 1
                        ">
                        Additional Locations
                    </p>
                    <p class="font-bold" *ngIf="
                            cardindex > 1 &&
                            cardindex < _signup.subscriptionPlans.length - 1
                        ">
                        {{card.locationCount}} x {{ card.locationSubscriptionPrice | currency }}
                    </p>
                    <p *ngIf="cardindex == 1">N/A</p>
                    <p *ngIf="
                            cardindex > 1 &&
                            cardindex == _signup.subscriptionPlans.length - 1
                        ">
                        -
                    </p>
                </div>
                <div class="flex justify-content-between">
                    <p class="text-sm">Additional Registers</p>
                    <p class="font-bold" *ngIf="
                            cardindex > 1 &&
                            cardindex < _signup.subscriptionPlans.length - 1
                        ">
                        {{card.RegisterCount}} x {{ card.registerSubscriptionPrice | currency }}
                    </p>
                    <p *ngIf="cardindex == 1">N/A</p>
                    <p *ngIf="
                            cardindex > 1 &&
                            cardindex == _signup.subscriptionPlans.length - 1
                        ">
                        -
                    </p>
                </div>
            </div>
            <button pButton pRipple type="button" [class]="
                    _signup.selectedPlan?.id == card?.id
                        ? 'selected-btn'
                        : 'p-button-outlined select-btn'
                " (click)="subscriptionPlanSelected(card)">
                <span *ngIf="!card.isCustom">
                    <span *ngIf="_signup.selectedPlan?.id == card?.id && !card?.isCustom">Selected</span>
                    <span *ngIf="_signup.selectedPlan?.id != card?.id && !card?.isCustom">Select</span>
                </span>
                <span *ngIf="card.isCustom">
                    <span *ngIf=" card.isCustom">Contact Us</span>
                </span>

            </button>
        </div>
    </div>

    <div class="w-full" *ngIf="cardindex > -1">
        <div class="card-list-style-hndler text-center" *ngFor="let item of card.featureData">
            <ul>
                <li>{{ item.value }}</li>
                <!-- const result = str.replace(/[A-Z]/g, ' $&').trim(); -->
            </ul>
        </div>
    </div>
</div>

