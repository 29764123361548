<!-- 1st Step -->
<ng-container *ngIf="_global.currentTour === 1">
<span class="tour-arrow tour-arrow-1"></span>
<span class="tour tour-1 p-3">
  <div class="tour-head flex justify-content-between align-items-center ">
    <span style="color:#1B1E27;" class="text-sm" >Step {{_global.currentTour}} of {{_global.totalTour}}</span>
    <img class="cursor-pointer" src="assets/images/cancel.svg" alt="Close" (click)="skipTour()" width="12px" >
  </div>
  <div class="tour-body">
    <div class="py-3 font-semibold text-xl">This is your dashboard</div>
    <p class="small-text p-0 m-0">This is where you'll track progress, see tasks to  complete, and monitor key insights about your  business.</p>
  </div>
  <div class="tour-footer pt-3 flex justify-content-end">
    <span class="tour-footer-skip-btn mx-1 cursor-pointer" (click)="skipTour()">Skip tour</span>
    <span class="tour-footer-tell-me-more mx-1 cursor-pointer" (click)="nextTour()" >Tell me more</span>
  </div>
</span> 
</ng-container>
 
<!--/ 1st Step -->
 <!-- 2nd Step-->
  <ng-container *ngIf="_global.currentTour === 2">
 <span class="tour-arrow tour-arrow-2"></span>
 <span class="tour tour-2 p-3">
   <div class="tour-head flex justify-content-between align-items-center ">
     <span style="color:#1B1E27;" class="text-sm">Step {{_global.currentTour}} of {{_global.totalTour}}</span>
     <img class="cursor-pointer" src="assets/images/cancel.svg" alt="Close" (click)="skipTour()" width="12px">
   </div>
   <div class="tour-body">
    <div class="py-3 font-semibold text-xl">To-do list to setup your store</div>
    <p class="small-text m-0 p-0">These are the key steps to set up your store. Complete all steps to launch your store and start selling!</p>
  </div>
  <div class="tour-footer pt-3 flex justify-content-end">
    <span class="tour-footer-tell-me-more mx-1 cursor-pointer" (click)="nextTour()" >Great</span>
   </div>
 </span>
  </ng-container>

 <!--/ 2nd Step-->
  <!-- 3rd Step-->
  <ng-container *ngIf="_global.currentTour === 3">
    <span class="tour-arrow tour-arrow-3"></span>
    <span class="tour tour-3 p-3">
      <div class="tour-head flex justify-content-between align-items-center ">
        <span style="color:#1B1E27;" class="text-sm">Step {{_global.currentTour}} of {{_global.totalTour}}</span>
        <img class="cursor-pointer" src="assets/images/cancel.svg" alt="Close" (click)="skipTour()" width="12px">
      </div>
      <div class="tour-body">
       <div class="py-3 font-semibold text-xl">Design your storefront here</div>
       <p class="small-text p-0 m-0">Customize your webstore in this section. Use the Editor to design your layout, connect a domain, and make it live for the customers.</p>
     </div>
     <div class="tour-footer pt-3 flex justify-content-end">
       <span class="tour-footer-tell-me-more mx-1 cursor-pointer" (click)="nextTour()" >I got it!</span>
      </div>
    </span>
  </ng-container>
  <!--/ 3rd Step-->
  <!-- 4th Step-->
  <ng-container *ngIf="_global.currentTour === 4">
    <span class="tour-arrow tour-arrow-4"></span>
    <span class="tour tour-4 p-3">
      <div class="tour-head flex justify-content-between align-items-center ">
        <span style="color:#1B1E27;" class="text-sm">Step {{_global.currentTour}} of {{_global.totalTour}}</span>
        <img class="cursor-pointer" src="assets/images/cancel.svg" alt="Close" (click)="skipTour()" width="12px">
      </div>
      <div class="tour-body">
       <div class="py-3 font-semibold text-xl">Your own product section</div>
       <p class="small-text m-0 p-0">In the Products section, you can add/import spel items to your store, upload images, set prices, In and manage inventory in real-time.</p>
     </div>
     <div class="tour-footer pt-3 flex justify-content-end">
       <span class="tour-footer-tell-me-more mx-1 cursor-pointer" (click)="nextTour()" >Interesting!</span>
      </div>
    </span>
  </ng-container>
  <!--/ 4th Step-->