import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state('expanded', style({ height: '*' })), // Height increases to fit content
      state('collapsed', style({ height: '0' })), // Collapsed state
      transition('expanded <=> collapsed', animate('500ms ease-in-out')), // Adjust the duration as needed
    ]),]
})

export class WelcomeComponent implements OnInit {
 
  _global = GlobalService
  slides = ['Slide 1', 'Slide 2', 'Slide 3'];
  currentSlide = 0;
  slideOffset:any;
  activeOrder:any = 1
  dataList = [
    {
        "title": "Build Your Webstore Effortlessly",
        "description1": "Create a beautiful, fully customized storefront that reflects your brand's",
        "description2": " identity-no technical expertise neede.",
        "order": 1,
        "imageUrl": "assets/images/effortlessly.png",
    },
    {
        "title": "Manage Your Inventory & Orders in One Place",
         "description1": "Easily add products, track inventory, and handle orders from your dashboard.",
         "description2": "Simplify your daily tasks so you can focus on growth.",
        "order": 2,
        "imageUrl": "assets/images/manage_order.png",
    },
 {
      "title": "Reach More Customers with Social Media",
       "description1": "When you're ready, expand your stor's reach by connecting to social ",
       "description2": "media channels. We make it easy to sell everywhere.",
      "order": 3,
      "imageUrl": "assets/images/social_media.png",
  },
   
]
  constructor(
    public _businessSetup: BusinessSetupService,
    private _userService: UserService,
    private _router: Router,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    this.activeSideBar(1) 
    setInterval(() => {
      this.nextSlide();
    }, 3000); 
 }

 activeSideBar(value:any) {
  this.slideOffset ={
    offset:-0,
    page:value
  };
  this.activeOrder = value
}

nextSlide() {
  const totalSlides = this.dataList.length;
  
  let nextOrder = this.activeOrder + 1;
  if (nextOrder > totalSlides) {
    nextOrder = 1; 
  }
  this.activeSideBar(nextOrder);
}
goTOHome(){
  this._abd.showSpinner()
 this._userService.isFirstLoginComplete().subscribe(res => {
   if (res.success) {
    localStorage.setItem('isFirstLogin','true')
    this._abd.hideSpinner()
           this._router.navigate([""])
   }
 })

}





}


