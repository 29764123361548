<div class="top-title">
    <span class="back-btn mx-4"  (click)="back()">
        <img class="px-2" src="assets/images/back_arrow.svg" alt=""> Back
      </span>
       <span>Webstore Templates</span>
       <span class="hide-btn mx-4"  (click)="back()">
        hide
      </span>
</div>
<div class="flex flex-column justify-content-center py-5 align-items-center">
    <h2>Select your template
    </h2>
    <p class="col-7 " style="text-align: center;">
        Website templates are perfect for a quick stort. Select the template you like and change whatever you want, like colors, fonts, and images. Personalize the template for your business, and your site is ready to publish.
    </p>
</div>
<div class="flex justify-content-center">

    <div *ngFor="let store of _globals?.webStoreConfiguration" class="col flex justify-content-center box mx-5" [style.background-image]="'url(' + store?.imageUrl+ ')'">
        <div class="flex align-items-end mb-4 w-10" > 
         <span class="m-1 w-full "    [ngClass]="store?.isAvailable === true ? 'usetheme-btn': 'disabled'"  (click)="setTheme('use',store)">
            Use Template
         </span>
         <span class="m-1 w-full " [ngClass]="store?.isAvailable === true ? 'preview-btn': 'disabled'"   (click)="setTheme('preview',store)">
            Preview Template
         </span>
        </div> </div>
         
</div>
<div class="flex justify-content-end align-content-center m-5 ">
</div>
