import { AppBaseDataService } from './_services/app-base-data.service';
import { UserService } from 'src/app/_services/user.service';
import { Component, HostListener, Inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WHITE_LABEL, WhiteLabelList } from './_data/white-label-list';
import { GlobalService } from './_services/global.service';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser'
import { FilterServiceService } from './_services/filter-service.service';
import { filter, map, mergeMap, Subject } from 'rxjs';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    _globals = GlobalService
    menuMode = 'static';
    dialogTimeInterval: any
    
    constructor(private primengConfig: PrimeNGConfig,
        public _userService: UserService,
        public _abd: AppBaseDataService,
        private _router: Router,
        private title: Title,
        private _activatedRoute: ActivatedRoute,
        private _filterService: FilterServiceService,
        @Inject(DOCUMENT) private _document: HTMLDocument) {
        this.setTimeout();
        this.userInactive.subscribe(res => {
            console.log('user has been inactive for 3s')
            // debugger;
            if (!this.displayUserInactivity && !window.location.href.includes('/pages/signin')) {
                this.displayUserInactivity = true
                this.userDialogTimer = 60
                clearTimeout(this.userActivityTimer)

                this.dialogTimeInterval = setInterval(() => {
                    this.userDialogTimer--
                    if (this.userDialogTimer <= 0) {
                        const xx = window.location.href
                        this.displayUserInactivity = false
                        clearInterval(this.dialogTimeInterval)
                        localStorage.clear();
                        location.reload();
                        this._router.navigate([''])
                    } else {

                    }

                }, 1000);
            }
        });

        _router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.id == 1) {
                    //let last = localStorage.getItem('goto')
                }
                else {
                    let href = window.location.href
                    if (!href.includes('%')) {
                        let x = window.location.origin + '/#' + event.url
                        if (this.isAllowedToSave(x)) {
                            const old = localStorage.getItem('goto')
                            localStorage.setItem('goto', x)
                            if (old && old !== x)
                                this._filterService.RestFiltersandPages()
                        }
                    }
                }
            }
        })
    } //-- end constructor
    isAllowedToSave(href: string) {
        const notAllowed = [
            '/app/new-product',
            '/app/transfer-out-form',
            '/app/transfers-receive-form',
            '/app/customer-details',
            '/app/purchase-orders-create-edit',
            '/app/purchase-orders-receive-open',
            '/app/purchase-orders-closed',
            '/app/users-new-edit',
            '/purchase-orders-receive-open',
            '/app/sales-invoice-detail',
            'app/purchase-orders-receive-closed-for-receiving',
            '/app/purchase-orders-receive-direct',
            '/select-template',
            'template-preview',
        ]
        let found = false
        for (let i = 0; i < notAllowed.length; i++) {
            if (href.includes(notAllowed[i])) {
                found = true
                break
            }
        }
        return !found
    }
    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';


        this._userService.autologin()
        let href = window.location.hash
        if (href.includes('/handheld')) localStorage.setItem('whichRoute', 'handheld')
        else localStorage.setItem('whichRoute', 'any')
        try {
            let favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
            favIcon.href = "assets/images/" + this._globals.whiteLabel.favIcon + ".ico";
            // favIcon.href = "assets/images/favicon-cbd.ico";
        }
        catch
        {
        }
        document.documentElement.style.setProperty('--brandColor', this._globals.whiteLabel.themeData.brandColor);
        document.documentElement.style.setProperty('--brandColorDark', this._globals.whiteLabel.themeData.brandColorDark);
        document.documentElement.style.setProperty('--brandColorLight', this._globals.whiteLabel.themeData.brandColorLight);
        document.documentElement.style.setProperty('--bs-primary', this._globals.whiteLabel.themeData.brandColor);
        document.documentElement.style.setProperty('--brandSecondaryColor', "#171046");
    }
    async setCurrentUser() {
        const model = JSON.parse(localStorage.getItem('user'));
        const isTokenValid = this._userService.isUserTokenExpired()
        if (model && !isTokenValid && this._userService.isUserAutoLogin()) {
            await this._userService.setCurrentUser(model)
            this._userService.setUserTokenExpiryTime()
            if (window.location.href.includes('/handheld'))
                this._router.navigate(['handheld/signin'])
            else
                this._router.navigate(['pages/signin'])
        } else {
            // else clear localstorage data
            this._userService.logout()
            
           
            
        }
    }

    displayUserInactivity = false
    userActivityMins = 30;
    userActivityInMilliSec = this.userActivityMins * 60 * 1000
    userActivityTimer: any
    userInactive: Subject<any> = new Subject();
    userDialogTimer = 60
    waitingForRefreshToken = false

    setTimeout() {
        // console.log('in time out')
        this.userActivityTimer = setTimeout(() => this.userInactive.next(undefined), this.userActivityInMilliSec);

        const today = Date.now()
        let xx = localStorage.getItem('lastLoginDT')

        if (!xx) return
        const dt = (new Date(Number(xx))).getTime()

        if (today > dt && !this.waitingForRefreshToken) {

            const obj = JSON.parse(localStorage.getItem('user'));
            if (!obj) return

            this.waitingForRefreshToken = true
            this._userService.getRefreshToken({
                "RefreshToken": obj.refreshToke
            }).subscribe(res => {
                if (res.success) {

                    let user = JSON.parse(localStorage.getItem('user'))
                    user.token = res.data.access_token
                    user.refreshToke = res.data.refresh_token
                    user.expires_in = res.data.expires_in

                    this._userService.setCurrentUser(user)

                    this.waitingForRefreshToken = false
                }
            })

        } //else console.log('todayyyyy', today, dt)
    }

    clearAutologoffHandle() {
        this.displayUserInactivity = false
        clearInterval(this.dialogTimeInterval)
        clearTimeout(this.userActivityTimer);
        this.setTimeout()
    }

    @HostListener('document:mousemove') refreshUserStateMouse() {
        clearTimeout(this.userActivityTimer);
        this.setTimeout();
    }

    @HostListener('document:keydown') refreshUserStateKeydown() {
        clearTimeout(this.userActivityTimer);
        this.setTimeout();
    }

    @HostListener('document:touchstart') refreshUserStateTouch() {
        clearTimeout(this.userActivityTimer);
        this.setTimeout();
    }

    // @HostListener('document:click') refreshUserStateClick() {
    //     // console.log('Clicked')
    // //     let userAttendance = localStorage.getItem('userAttendance')
    // //    let userCheckInType = localStorage.getItem('userCheckInType')
    // //   let enableTimeAttendance = localStorage.getItem('enableTimeAttendance')
    // //  if(enableTimeAttendance === 'true'){
    // //       if(userAttendance === 'not-check-in' && userCheckInType === 'force-checkIn'){
    // //         this._userService.isShowCheckIn = true
    // //         if(this._userService.isCheckIn === false){
    // //            this._userService.isCheckIn = true
    // //     }
    // //  }
    // //  }
    
    // }

  
}
